export default {
  data () {
    return {
      generateDialogUrl: '',
      generateDocumentName: 'overview',
      generateDocumentUrl: ''
    }
  },

  methods: {
    getIdea () {
      return this.sharedIdea || this.$store.state.idea
    },

    async generateDocument (format) {
      this.generateDialogUrl = ''
      this.generateDialog = true

      const response = await this.$http.post(
        this.generateDocumentUrl,
        {
          ideaId: this.getIdea().id,
          format: format,
          active: this.active
        }
      )

      if (response.status === 200) {
        this.generateDialogUrl = response.data.payload.url
      }
    }
  }
}
