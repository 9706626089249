<template>
  <!-- Journal -->
  <div id="journal">

    <!-- Container -->
    <div class="container-1200">

      <!-- Loader -->
      <div v-if="loading === true">
        <loader />
      </div>
      <!-- /Loader -->

      <div v-if="notFound === true" class="content not-found">
        <h1>{{ $t('sidebar.businessPlan') }}</h1>
        <p class="text-lead">
          {{ $t('notFound') }}
        </p>
      </div>

      <div v-if="loading === false && notFound === false" class="content journal-inner">

        <!-- Header -->
        <header class="d-flex justify-content-between align-items-center mb-3">

          <!-- Title and Subtitle -->
          <div>

            <!-- Title -->
            <h1>{{ $t('sidebar.businessPlan') }}</h1>
            <!-- /Title -->

          </div>
          <!-- /Title and Subtitle -->

          <!-- Download and Share Buttons -->
          <div class="action-buttons">
            <ib-tooltip
              popper-class="small center"
              :content="$t('export')"
            >
              <!-- Download PDF -->
              <el-dropdown trigger="click" @command="generateDocument">
                <a class="el-dropdown-link print" @click.prevent>
                  <i class="ib-icon-download h3 text-silver hover-scale" />
                </a>
                <el-dropdown-menu slot="dropdown" class="export-dropdown-menu">
                  <el-dropdown-item
                    class="d-flex"
                    icon="ib-icon-export-pdf"
                    command="pdf"
                  >
                    {{ $t('exportToPdf') }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    class="d-flex"
                    icon="ib-icon-export-word"
                    command="doc"
                  >
                    {{ $t('exportToWord') }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- /Download PDF -->
            </ib-tooltip>

            <!--<a class="reader-mode">Reader Mode</a>-->
          </div>
          <!-- /Download and Share Buttons -->

        </header>
        <!-- /Header -->

        <!-- Journal Section Content -->
        <div ref="journal-flex" class="journal-flex">
          <div ref="journal-wrapper" class="journal-content-wrapper">
            <!-- Journal StoryMode and Dynamically Generated  Section (Pages) -->

            <!-- Components from Story Mode -->
            <template v-for="(componentEl, index) in $store.state.idea.journal.journalSwitchBars">

              <component
                :is="componentEl.sectionName"
                v-if="componentEl.sectionName"
                :key="index"
                share-mode
                :all-pages-text-data="componentEl"
              />

              <div v-else-if="isOption(componentEl, 'switch')" :key="'dynamically' + index" class="journal-content generated-section">
                <div class="sub chapter">
                  <header>
                    <h2>{{ componentEl.title }}</h2>
                    <hr>
                  </header>

                  <div
                    class="content-text"
                    v-html="componentEl.subTitle"
                  />
                </div>
              </div>

            </template>
            <!-- /Components from Story Mode -->

            <!-- /Journal StoryMode and Dynamically Generated  Section (Pages) -->
          </div>

        </div>
        <!-- Journal Section Content -->

        <!-- Dialog Export/Generate -->
        <dialog-generate
          api="story-mode/journal/generate"
          :format="generateFormat"
          :dialog-visible="generateDialog"
          @close="generateDialog = false"
        />
        <!-- /Dialog Export/Generate -->

        <!--<dialog-edit-section />-->
      </div>
    </div>
  </div>
  <!-- /Journal -->
</template>

<script>
import Competitors from '@/views/Home/StoryMode/Journal/Pages/Competitors'
import Cover from '@/views/Home/StoryMode/Journal/Pages/Cover'
import Custom from '@/views/Home/StoryMode/Journal/Pages/Custom'
import Customers from '@/views/Home/StoryMode/Journal/Pages/Customers'
import DialogAddNewSection from './Dialogs/DialogAddNewSection'
import DialogGenerate from '@/views/Home/StoryMode/Components/Dialogs/DialogGenerate'
import DirectCosts from '@/views/Home/StoryMode/Journal/Pages/DirectCosts'
import Distribution from '@/views/Home/StoryMode/Journal/Pages/Distribution'
import Financing from '@/views/Home/StoryMode/Journal/Pages/Financing'
import Idea from '@/views/Home/StoryMode/Journal/Pages/Idea'
import Loader from '@/components/Loader'
import ManagementTeam from '@/views/Home/StoryMode/Journal/Pages/ManagementTeam'
import Market from '@/views/Home/StoryMode/Journal/Pages/Market'
import MarketPotential from '@/views/Home/StoryMode/Journal/Pages/MarketPotential'
import MarketingBudget from '@/views/Home/StoryMode/Journal/Pages/MarketingBudget'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinGenerateDocument from '@/mixins/generateDocument'
import OtherOverheads from '@/views/Home/StoryMode/Journal/Pages/OtherOverheads'
import Ownership from '@/views/Home/StoryMode/Journal/Pages/Ownership'
import Partner from '@/views/Home/StoryMode/Journal/Pages/Partner'
import Product from '@/views/Home/StoryMode/Journal/Pages/Product'
import ProfitAndCashFlow from '@/views/Home/StoryMode/Journal/Pages/ProfitAndCashFlow'
import Promotion from '@/views/Home/StoryMode/Journal/Pages/Promotion'
import RevenueStreams from '@/views/Home/StoryMode/Journal/Pages/RevenueStreams'
import StaffAndPayroll from '@/views/Home/StoryMode/Journal/Pages/StaffAndPayroll'
import StartupCosts from '@/views/Home/StoryMode/Journal/Pages/StartupCosts'
import SwotAnalysis from '@/views/Home/StoryMode/Journal/Pages/SwotAnalysis'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'BusinessPlanShared',

  components: {
    Competitors,
    Cover,
    Custom,
    Customers,
    DialogAddNewSection,
    DialogGenerate,
    DirectCosts,
    Distribution,
    Financing,
    Idea,
    Loader,
    ManagementTeam,
    Market,
    MarketPotential,
    MarketingBudget,
    OtherOverheads,
    Ownership,
    Partner,
    Product,
    ProfitAndCashFlow,
    Promotion,
    RevenueStreams,
    StaffAndPayroll,
    StartupCosts,
    SwotAnalysis
  },

  mixins: [
    journalMethods,
    MixinGenerateDocument,
    MixinApiCalculations
  ],

  data () {
    return {
      generateDialog: false,
      generateFormat: '',
      loading: true,
      notFound: false,
      generateDocumentName: 'journal',
      generateDocumentUrl: '/api/v1/story-mode/journal/generate'
    }
  },

  mounted () {
    this.getSharedJournalElements()
    window.addEventListener('resize', this.resizePages)
  },

  destroyed () {
    window.removeEventListener('resize', this.resizePages)
  },

  methods: {
    generateDocument (format) {
      this.generateFormat = format
      this.generateDialog = true
    },

    setElementHeight (elemName) {
      if (Array.isArray(elemName)) {
        for (let m = 0; m < elemName.length; m++) {
          this.setElementHeightFunctionality(elemName[m])
        }
      } else {
        this.setElementHeightFunctionality(elemName)
      }
    },

    setElementHeightFunctionality (el) {
      const elem = document.querySelectorAll(el)
      let elemHeight = []
      let elemPosition = []

      for (let i = 0; i < elem.length; i++) {
        const elH = elem[i].offsetHeight
        elemHeight.push(elH)
        elemPosition.push(i)
        const largestValue = Math.max(...elemHeight)

        if ((i + 1) % 4 === 0 && i !== 0) {
          for (let k = 0; k < elemPosition.length; k++) {
            elem[elemPosition[k]].style.height = largestValue + 'px'
          }
          elemPosition = []
          elemHeight = []
        } else if (elem.length - (i + 1) < 4 && elem.length - 1 === i) {
          for (let k = 0; k < elemPosition.length; k++) {
            elem[elemPosition[k]].style.height = largestValue + 'px'
          }
          elemPosition = []
          elemHeight = []
        }
      }
    },

    getSharedJournalElements () {
      this.$http.get(
        'story-mode/journal/shared',
        { params: { token: this.$route.params.token } }
      ).then(response => {
        this.$store.commit('user/setUser', response.data.payload)
        this.$store.commit('idea/setIdea', response.data.payload)
        this.$store.commit('idea/setJournalSwitchSidebarElements', response.data.payload.journalElements)

        this.setStartupCosts(response.data.payload.calculation.startupCosts)
        this.setStaffAndPayroll(response.data.payload.calculation.staffAndPayrolls)
        this.setFinancing(response.data.payload.calculation.financing)
        this.setRevenueStreams(response.data.payload.calculation.revenueStreams)
        this.setDirectCosts(response.data.payload.calculation.directCosts)
        this.setMarketingBudget(response.data.payload.calculation.marketingBudget)
        this.setOtherOverheads(response.data.payload.calculation.otherOverheads)
        this.setProfitAndCashFlow(response.data.payload.calculation.profitAndCashFlow)
        this.setTotalCosts(response.data.payload.calculation.totalCosts)
      }).catch(() => {
        this.notFound = true
      }).finally(() => {
        this.loading = false
        setTimeout(() => {
          this.resizePages()
        }, 100)
        setTimeout(() => {
          this.$nextTick(() => {
            this.setElementHeight([
              '.customer-card .top-segment h3',
              '.customer-card .top-segment .desc',
              '.customer-card .top-segment .pain-points',
              '.competitor-card .top-segment h3',
              '.competitor-card .top-segment .desc',
              '.promotion-card .card-bottom h3',
              '.distribution-card .card-bottom h3',
              '.distribution-card .card-bottom .desc',
              '.partner-card .card-bottom h3',
              '.partner-card .card-bottom .desc',
              '.product-card .card-bottom h3',
              '.product-card .card-bottom .category',
              '.member-card .card-bottom h3',
              '.member-card .card-bottom .position'
            ])
          })
        }, 2000)
      })
    },

    cardImage (img) {
      return img ? 'background-image: url(' + img + ')' : ''
    },

    /**
     * Scale Business Plan pages based on device width
     * Pages will be zoomed out on smalled devices
     */
    resizePages () {
      const fixedWrapperWidth = 840
      const wrapperFlexRef = this.$refs['journal-flex']
      if (!wrapperFlexRef) {
        return
      }

      const wrapperRef = this.$refs['journal-wrapper']
      const wrapperWidth = wrapperFlexRef.offsetWidth
      const calculatedScale = wrapperWidth / fixedWrapperWidth
      wrapperRef.style.transform = `scale(${calculatedScale <= 1 ? calculatedScale : 1})`

      // Fix wrapper height because `scale` keeps original space of wrapper and leaves empty space
      setTimeout(() => {
        wrapperFlexRef.style.height = 'auto'

        if (calculatedScale < 1) {
          const wrapperOffsetHeight = wrapperFlexRef.offsetHeight
          const calculatedHeight = (wrapperOffsetHeight * calculatedScale) + 20

          wrapperFlexRef.style.height = `${calculatedHeight}px`
        }
      }, 100)
    }
  }
}
</script>

<style scoped lang="scss">
  #journal {
    margin-left: 0;
    background-color: $color-text-lightest-blue;
  }

  #journal .container-1200 {
    max-width: 840px;
  }

  #journal .content.not-found {
    min-height: 100vh;
  }
  #journal .content.not-found h1,
  #journal .content.not-found p {
    margin: 0;
    padding: 0;
  }
  #journal .content.not-found p {
    opacity: 0.5;
  }
  #journal .content .journal-flex .journal-content-wrapper {
    max-width: 100%;
  }
  #journal .content .journal-flex .journal-content {
    margin-right: 0;
  }
  #journal .content .journal-flex .journal-content .content-menus .mini-actions,
  #journal .content .journal-flex .journal-content .edit-wrapper .placeholder,
  #journal .content .journal-flex .journal-content .edit-icon,
  #journal .content .journal-flex .journal-content textarea,
  #journal .content .journal-flex .journal-content button {
    display: none !important;
  }
  #journal .content .journal-flex .journal-content .edit-element {
    display: block !important;
  }
</style>
